@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
  box-sizing: border-box;
  font-family: "poppins";
}

* login circles animation * .login-circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.login-circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  animation: animate 25s linear infinite;
  top: -150px;
}

.login-circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.login-circles li:nth-child(2) {
  left: 10%;
  width: 60px;
  height: 60px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.login-circles li:nth-child(3) {
  left: 70%;
  width: 50px;
  height: 50px;
  animation-delay: 4s;
}

.login-circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.login-circles li:nth-child(5) {
  left: 65%;
  width: 40px;
  height: 40px;
  animation-delay: 0s;
}

.login-circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.login-circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.login-circles li:nth-child(8) {
  left: 50%;
  width: 55px;
  height: 55px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.login-circles li:nth-child(9) {
  left: 20%;
  width: 35px;
  height: 35px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.login-circles li:nth-child(10) {
  left: 85%;
  width: 100px;
  height: 100px;
  animation-delay: 0s;
  animation-duration: 10s;
}

.login-circles li:nth-child(11) {
  left: 78%;
  width: 150px;
  height: 100px;
  animation-delay: 0s;
  animation-duration: 9s;
}

.login-circles li:nth-child(12) {
  left: 25%;
  width: 30px;
  height: 50px;
  animation-delay: 0s;
  animation-duration: 11s;
}

.login-circles li:nth-child(13) {
  left: 15%;
  width: 50px;
  height: 50px;
  animation-delay: 0s;
  animation-duration: 9s;
}

.login-circles li:nth-child(14) {
  left: 30%;
  width: 80px;
  height: 70px;
  animation-delay: 5s;
  animation-duration: 7s;
}

.login-circles li:nth-child(15) {
  left: 30%;
  width: 80px;
  height: 70px;
  animation-delay: 5s;
  animation-duration: 7s;
}

.login-circles li:nth-child(16) {
  left: 55%;
  width: 80px;
  height: 80px;
  animation-delay: 10s;
  animation-duration: 15s;
}

.login-circles li:nth-child(16) {
  left: 55%;
  width: 80px;
  height: 80px;
  animation-delay: 12s;
  animation-duration: 15s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.9;
    border-radius: 0;
  }

  100% {
    transform: translateY(1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
